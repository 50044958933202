import React from "react"
import { handleAuthentication } from "../utils/auth"
import PropTypes from 'prop-types';
import { getUrlParam } from "../utils/helpers";

const Callback = (props) => {
    let redirectUrl = getUrlParam("redirect", props.location.search);
    redirectUrl = redirectUrl ? redirectUrl : "/";
    console.log(`Setting redirect url as ${redirectUrl}`);
    handleAuthentication(redirectUrl)
    return <p>Loading...</p>
}

Callback.propTypes = {
    location: PropTypes.object.isRequired,
    props: PropTypes.object
}


export default Callback
